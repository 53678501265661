import React from "react";
import Contact from "../includes/Contact";
import image from "../resources/imgs/1on1.jpg";
import universe from '../resources/imgs/universum.jpg'
import ParallaxCoaching from '../ParallaxFiles/ParallaxCoaching'

export default function Coaching() {
  return (
    <div>
      <ParallaxCoaching/>
    </div>
  );
}
