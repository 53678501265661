import React, { Component } from 'react'
import ParallaxFItness from '../ParallaxFiles/ParallaxFitness'

export default class Fitness extends Component {
  render() {
    return (
      <div>
        <ParallaxFItness/>
      </div>
    )
  }
}
