import React from 'react'
import ParallaxSuccess from '../../ParallaxFiles/ParallaxSuccess'

export default function Success() {
  return (
    <div>
      <ParallaxSuccess/>
    </div>
  )
}

