import React from "react";
import '../../resources/css/Navbar.css'

export default function () {
  return (
    <div>
      <div className="box text-center">
        <h2 id="title">THE SOUL CHANNEL</h2>
        <p id="description">Business · Fitness · Youtube Coach</p>
      </div>
    </div>
  );
}
